<section id="prezzi">
  <div class="col-md-12">
    <div class="row mat-typography" style="overflow: hidden">
      <div class="container col-md-6"></div>
      <div
        class="container col-sm-12 col-md-6 text-box-prezzi"
        data-aos="slide-left"
        data-aos-duration="1500"
        data-aos-offset="500"
      >
        <p class="title centered-content">PREZZI</p>
        <P class="title">Dispositivo:</P>
        <p class="content">
          <span class="prices">99,00 €**</span>
        </p>
        <P class="title">Abbonamento annuale:</P>
        <p class="content">
          <span class="prices">60,00 €*</span> annui per dispositivo
        </p>
        <P class="title">Abbonamento mensile:</P>
        <p class="content">
          <span class="prices">6,00 €*</span>
          al mese per dispositivo (ogni mese puoi decidere se rinnovare o meno)
        </p>
        <p class="ps">
          *i prezzi sono tutti compresi di I.V.A., gli abbonamenti sono
          obbligatori per visualizzare le posizioni.
        </p>
        <p class="ps">
          **per la fruizione del servizio sarà necessario sottoscrivere un
          abbonamento.
        </p>
      </div>
    </div>
  </div>
</section>
