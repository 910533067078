<div
  class="data-form-container container animate__animated animate__fadeInDown"
>
  <div *ngIf="!outOfStock">
    <h1 class="title-form mat-typography">Inserisci i tuoi dati</h1>
    <mat-vertical-stepper linear #stepper>
      <mat-step [stepControl]="datiPersona">
        <form [formGroup]="datiPersona" (ngSubmit)="termsVisible()">
          <ng-template matStepLabel>Informazioni d'identità</ng-template>
          <mat-form-field appearance="outline" class="form-input">
            <mat-label>Nome</mat-label>
            <input
              formControlName="nome"
              matInput
              placeholder="Es: Carlo, Mario, etc."
              required
              name="Nome"
            />
            <mat-error *ngIf="!datiPersona.get('nome')?.valid">{{
              getRequiredError()
            }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="form-input">
            <mat-label>Cognome</mat-label>
            <input
              formControlName="cognome"
              matInput
              required
              placeholder="Es: Bianchi, Rossi, etc."
              name="Cognome"
            />
            <mat-error *ngIf="!datiPersona.get('cognome')?.valid">{{
              getRequiredError()
            }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="form-input">
            <mat-label>C.F.</mat-label>
            <input
              formControlName="CF"
              matInput
              required
              placeholder="Codice Fiscale"
              name="CodFisc"
            />
            <mat-error *ngIf="!datiPersona.get('CF')?.valid">{{
              getRequiredError()
            }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="form-input">
            <mat-label>E-mail</mat-label>
            <input
              formControlName="email"
              matInput
              required
              placeholder="es: nomeutente@dominio.it"
              name="E-mail"
            />
            <mat-error *ngIf="!datiPersona.get('email')?.valid">{{
              getEmailError()
            }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="form-input">
            <mat-label>P.IVA</mat-label>
            <input
              formControlName="pIVA"
              matInput
              placeholder="Partita IVA"
              name="pIva"
              (keyup)="setpecCuRequired()"
            />
            <mat-error *ngIf="!datiPersona.get('pIVA')?.valid">{{
              getpIVAError()
            }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="form-input">
            <mat-label>PEC o Codice Univoco</mat-label>
            <input
              formControlName="pecCu"
              matInput
              placeholder="Pec o Codice Univoco"
              id="pecCu"
              name="PecCu"
              (keyup)="setpIVARequired()"
            />
            <mat-error *ngIf="!datiPersona.get('pecCu')?.valid">{{
              getRequiredError()
            }}</mat-error>
          </mat-form-field>
          <div class="mat-typography" style="width: 80%" id="terms">
            <mat-checkbox
              formControlName="terms"
              color="primary"
              (click)="termsHidden()"
              class="col-md-4"
            >
              <span>
                Accetto
                <a class="bg-white" target="_blank" href="./privacy.html"
                  >termini e condizioni</a
                >
              </span>
            </mat-checkbox>
            <br />
            <span id="termini" color="warn" style="visibility: hidden"
              >Devi accettare i termini di servizio per continuare</span
            >
          </div>
          <p style="position: relative; height: 36px">
            <button
              mat-raised-button
              matStepperNext
              color="primary"
              class="next-btn"
            >
              Next
            </button>
          </p>
        </form>
      </mat-step>
      <mat-step [stepControl]="datiDomicilio">
        <form [formGroup]="datiDomicilio" class="mat-typography">
          <ng-template matStepLabel>Informazioni di spedizione</ng-template>
          <mat-form-field appearance="outline" class="form-input">
            <mat-label>Provincia</mat-label>
            <mat-select required formControlName="provincia">
              <mat-option value="AG">Agrigento</mat-option>
              <mat-option value="AL">Alessandria</mat-option>
              <mat-option value="AN">Ancona</mat-option>
              <mat-option value="AO">Aosta</mat-option>
              <mat-option value="AR">Arezzo</mat-option>
              <mat-option value="AP">Ascoli piceno</mat-option>
              <mat-option value="AT">Asti</mat-option>
              <mat-option value="AV">Avellino</mat-option>
              <mat-option value="BA">Bari</mat-option>
              <mat-option value="BT">Barletta-andria-trani</mat-option>
              <mat-option value="BL">Belluno</mat-option>
              <mat-option value="BN">Benevento</mat-option>
              <mat-option value="BG">Bergamo</mat-option>
              <mat-option value="BI">Biella</mat-option>
              <mat-option value="BO">Bologna</mat-option>
              <mat-option value="BZ">Bolzano</mat-option>
              <mat-option value="BS">Brescia</mat-option>
              <mat-option value="BR">Brindisi</mat-option>
              <mat-option value="CA">Cagliari</mat-option>
              <mat-option value="CL">Caltanissetta</mat-option>
              <mat-option value="CB">Campobasso</mat-option>
              <mat-option value="CI">Carbonia-iglesias</mat-option>
              <mat-option value="CE">Caserta</mat-option>
              <mat-option value="CT">Catania</mat-option>
              <mat-option value="CZ">Catanzaro</mat-option>
              <mat-option value="CH">Chieti</mat-option>
              <mat-option value="CO">Como</mat-option>
              <mat-option value="CS">Cosenza</mat-option>
              <mat-option value="CR">Cremona</mat-option>
              <mat-option value="KR">Crotone</mat-option>
              <mat-option value="CN">Cuneo</mat-option>
              <mat-option value="EN">Enna</mat-option>
              <mat-option value="FM">Fermo</mat-option>
              <mat-option value="FE">Ferrara</mat-option>
              <mat-option value="FI">Firenze</mat-option>
              <mat-option value="FG">Foggia</mat-option>
              <mat-option value="FC">Forli’-cesena</mat-option>
              <mat-option value="FR">Frosinone</mat-option>
              <mat-option value="GE">Genova</mat-option>
              <mat-option value="GO">Gorizia</mat-option>
              <mat-option value="GR">Grosseto</mat-option>
              <mat-option value="IM">Imperia</mat-option>
              <mat-option value="IS">Isernia</mat-option>
              <mat-option value="SP">La spezia</mat-option>
              <mat-option value="AQ">L’aquila</mat-option>
              <mat-option value="LT">Latina</mat-option>
              <mat-option value="LE">Lecce</mat-option>
              <mat-option value="LC">Lecco</mat-option>
              <mat-option value="LI">Livorno</mat-option>
              <mat-option value="LO">Lodi</mat-option>
              <mat-option value="LU">Lucca</mat-option>
              <mat-option value="MC">Macerata</mat-option>
              <mat-option value="MN">Mantova</mat-option>
              <mat-option value="MS">Massa-carrara</mat-option>
              <mat-option value="MT">Matera</mat-option>
              <mat-option value="VS">Medio campidano</mat-option>
              <mat-option value="ME">Messina</mat-option>
              <mat-option value="MI">Milano</mat-option>
              <mat-option value="MO">Modena</mat-option>
              <mat-option value="MB">Monza e della brianza</mat-option>
              <mat-option value="NA">Napoli</mat-option>
              <mat-option value="NO">Novara</mat-option>
              <mat-option value="NU">Nuoro</mat-option>
              <mat-option value="OG">Ogliastra</mat-option>
              <mat-option value="OT">Olbia-tempio</mat-option>
              <mat-option value="OR">Oristano</mat-option>
              <mat-option value="PD">Padova</mat-option>
              <mat-option value="PA">Palermo</mat-option>
              <mat-option value="PR">Parma</mat-option>
              <mat-option value="PV">Pavia</mat-option>
              <mat-option value="PG">Perugia</mat-option>
              <mat-option value="PU">Pesaro e urbino</mat-option>
              <mat-option value="PE">Pescara</mat-option>
              <mat-option value="PC">Piacenza</mat-option>
              <mat-option value="PI">Pisa</mat-option>
              <mat-option value="PT">Pistoia</mat-option>
              <mat-option value="PN">Pordenone</mat-option>
              <mat-option value="PZ">Potenza</mat-option>
              <mat-option value="PO">Prato</mat-option>
              <mat-option value="RG">Ragusa</mat-option>
              <mat-option value="RA">Ravenna</mat-option>
              <mat-option value="RC">Reggio di calabria</mat-option>
              <mat-option value="RE">Reggio nell’emilia</mat-option>
              <mat-option value="RI">Rieti</mat-option>
              <mat-option value="RN">Rimini</mat-option>
              <mat-option value="RM">Roma</mat-option>
              <mat-option value="RO">Rovigo</mat-option>
              <mat-option value="SA">Salerno</mat-option>
              <mat-option value="SS">Sassari</mat-option>
              <mat-option value="SV">Savona</mat-option>
              <mat-option value="SI">Siena</mat-option>
              <mat-option value="SR">Siracusa</mat-option>
              <mat-option value="SO">Sondrio</mat-option>
              <mat-option value="TA">Taranto</mat-option>
              <mat-option value="TE">Teramo</mat-option>
              <mat-option value="TR">Terni</mat-option>
              <mat-option value="TO">Torino</mat-option>
              <mat-option value="TP">Trapani</mat-option>
              <mat-option value="TN">Trento</mat-option>
              <mat-option value="TV">Treviso</mat-option>
              <mat-option value="TS">Trieste</mat-option>
              <mat-option value="UD">udine</mat-option>
              <mat-option value="VA">Varese</mat-option>
              <mat-option value="VE">Venezia</mat-option>
              <mat-option value="VB">Verbano-cmat-usio-ossola</mat-option>
              <mat-option value="VC">Vercelli</mat-option>
              <mat-option value="VR">Verona</mat-option>
              <mat-option value="VV">Vibo valentia</mat-option>
              <mat-option value="VI">Vicenza</mat-option>
              <mat-option value="VT">Viterbo</mat-option>
            </mat-select>
            <mat-error *ngIf="!datiPersona.get('provincia')?.valid">{{
              getRequiredError()
            }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="form-input">
            <mat-label>Comune</mat-label>
            <input
              formControlName="comune"
              matInput
              required
              placeholder="Comune domicilio"
              name="Comune"
            />
            <mat-error *ngIf="!datiDomicilio.get('comune')?.valid">{{
              getRequiredError()
            }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="form-input">
            <mat-label>Indirizzo</mat-label>
            <input
              formControlName="indirizzo"
              matInput
              required
              placeholder="Via/Piazza, numero"
              name="Indirizzo"
            />
            <mat-error *ngIf="!datiDomicilio.get('indirizzo')?.valid">{{
              getRequiredError()
            }}</mat-error>
          </mat-form-field>
          <mat-form-field
            appearance="outline"
            class="form-input senza-freccette"
          >
            <mat-label>CAP</mat-label>
            <input
              formControlName="CAP"
              matInput
              required
              type="number"
              placeholder="CAP"
              name="CAP"
            />
            <mat-error *ngIf="!datiDomicilio.get('CAP')?.valid">{{
              getCAPError()
            }}</mat-error>
          </mat-form-field>
          <p style="position: relative; height: 36px">
            <button
              mat-raised-button
              matStepperNext
              color="primary"
              class="next-btn"
            >
              Next
            </button>
            <button
              mat-raised-button
              matStepperPrevious
              color="primary"
              class="back-btn"
            >
              Back
            </button>
          </p>
        </form>
      </mat-step>
      <mat-step [stepControl]="datiAcquisto">
        <form [formGroup]="datiAcquisto" class="mat-typography">
          <ng-template matStepLabel>Quantità e tipo di pagamento</ng-template>
          <!-- <mat-label style="margin-right: 30px">Tipo cliente:</mat-label>
        <mat-radio-group formControlName="tipoCliente">
          <br />
          <mat-radio-button style="margin-right: 20px" value="1"
            >Privato</mat-radio-button
          >
          <mat-radio-button value="2">Noleggiatore</mat-radio-button>
        </mat-radio-group> -->
          <br />
          <div *ngIf="datiAcquisto.get('tipoCliente')?.value == 1">
            <mat-form-field appearance="outline" class="form-input-numbers">
              <mat-label>Quantità</mat-label>
              <input
                type="number"
                formControlName="quantità"
                matInput
                required
                name="Quantità"
                min="1"
                id="quantità"
              />
              <mat-error *ngIf="!datiPersona.get('quantità')?.valid">{{
                getQuantitaError()
              }}</mat-error>
            </mat-form-field>
            <span style="margin-left: 30px">{{ getPrice(true) }},00 €</span>
          </div>
          <br />
          <div *ngIf="datiAcquisto.get('tipoCliente')?.value == 1">
            <mat-label style="margin-right: 30px"
              >Modalità di pagamento:</mat-label
            >
            <mat-radio-group formControlName="tipoPagamento">
              <br />
              <br />
              <mat-radio-button style="margin-right: 20px" value="1"
                >Paypal</mat-radio-button
              >
              <br />
              <br />
              <mat-radio-button style="margin-right: 20px" value="2"
                >Bonifico bancario</mat-radio-button
              >
              <br />
              <span>({{ scontoMetodoPag }},00 € sconto per dispositivo)</span>
              <br />
              <br />
              <mat-radio-button style="margin-right: 20px" value="3"
                >Carte di credito/debito/prepagate</mat-radio-button
              >
              <br />
              <span>({{ scontoMetodoPag }},00 € sconto per dispositivo)</span>
              <br />
              <br />
            </mat-radio-group>
            <mat-form-field
              appearance="outline"
              class="form-input"
              style="margin-left: 0"
            >
              <mat-label>Codice Rivenditore/Coupon</mat-label>
              <input
                formControlName="codRivenditore"
                matInput
                placeholder="Codice Rivenditore/Coupon"
                name="codRivenditore"
              />
              <mat-error *ngIf="!datiAcquisto.get('codRivenditore')?.valid"
                >Deve contenere 4 caratteri ed accetta solo numeri e lettere
                maiuscole</mat-error
              >
            </mat-form-field>
            <button mat-raised-button color="primary" (click)="couponControl()">
              Applica coupon
            </button>
            <br />
            <mat-form-field
              appearance="outline"
              class="form-input"
              style="margin-left: 0"
              *ngIf="datiAcquisto.get('quantità')?.value == 1"
            >
              <mat-label>Nome imbarcazione</mat-label>
              <input
                formControlName="nomeBarca"
                matInput
                placeholder="Nome imbarcazione"
                name="nomeBarca"
              />
            </mat-form-field>
            <mat-form-field
              appearance="outline"
              class="form-input"
              style="margin-left: 0; width: 100%"
              *ngIf="datiAcquisto.get('quantità')?.value > 1"
            >
              <mat-label>Nome imbarcazioni</mat-label>
              <textarea
                formControlName="nomiBarche"
                matInput
                rows="3"
                placeholder="Dividere i nomi con una virgola, es: Perla Nera, Olandese Volante, etc."
                name="nomiBarche"
              ></textarea>
            </mat-form-field>
          </div>
          <p style="position: relative; height: 36px">
            <button
              mat-raised-button
              matStepperNext
              color="primary"
              class="next-btn"
              (click)="initConfig()"
            >
              Next
            </button>
            <button
              mat-raised-button
              matStepperPrevious
              color="primary"
              class="back-btn"
            >
              Back
            </button>
          </p>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>CheckOut</ng-template>
        <div class="container mat-tipography">
          <table mat-table>
            <tr>
              <th mat-header-cell>Riepilogo informazioni</th>
            </tr>
            <tr>
              <td mat-cell>Nome</td>
              <td mat-cell>{{ datiPersona.get("nome")?.value }}</td>
            </tr>
            <tr>
              <td mat-cell>Cognome</td>
              <td mat-cell>{{ datiPersona.get("cognome")?.value }}</td>
            </tr>
            <tr>
              <td mat-cell>C.F.</td>
              <td mat-cell>{{ datiPersona.get("CF")?.value }}</td>
            </tr>
            <tr>
              <td mat-cell>Email</td>
              <td mat-cell>{{ datiPersona.get("email")?.value }}</td>
            </tr>
            <tr>
              <td mat-cell>Partita IVA</td>
              <td mat-cell>{{ datiPersona.get("pIVA")?.value }}</td>
            </tr>
            <tr>
              <td mat-cell>Pec/Codice Univoco</td>
              <td mat-cell>{{ datiPersona.get("pecCu")?.value }}</td>
            </tr>
            <tr>
              <td mat-cell>Provincia</td>
              <td mat-cell>{{ datiDomicilio.get("provincia")?.value }}</td>
            </tr>
            <tr>
              <td mat-cell>Comune</td>
              <td mat-cell>{{ datiDomicilio.get("comune")?.value }}</td>
            </tr>
            <tr>
              <td mat-cell>CAP</td>
              <td mat-cell>{{ datiDomicilio.get("CAP")?.value }}</td>
            </tr>
            <tr>
              <td mat-cell>Indirizzo</td>
              <td mat-cell>{{ datiDomicilio.get("indirizzo")?.value }}</td>
            </tr>
            <tr *ngIf="datiAcquisto.get('tipoCliente')?.value == 1">
              <td mat-cell>Quantità</td>
              <td mat-cell>{{ datiAcquisto.get("quantità")?.value }}</td>
            </tr>
            <tr *ngIf="datiAcquisto.get('quantità')?.value == 1">
              <td mat-cell>Nome Imbarcazione</td>
              <td mat-cell>{{ datiAcquisto.get("nomeBarca")?.value }}</td>
            </tr>
            <tr *ngIf="datiAcquisto.get('quantità')?.value > 1">
              <td mat-cell>Nomi Imbarcazioni</td>
              <td mat-cell>{{ datiAcquisto.get("nomiBarche")?.value }}</td>
            </tr>
            <tr *ngIf="datiAcquisto.get('tipoCliente')?.value == 1">
              <td mat-cell>Prezzo</td>
              <td
                mat-cell
                *ngIf="datiAcquisto.get('tipoPagamento')?.value == 1"
              >
                {{ getPrice(true) }},00 € + {{ shipping }},00 € (costo di
                spedizione)
              </td>
              <td
                mat-cell
                *ngIf="datiAcquisto.get('tipoPagamento')?.value != 1"
              >
                {{ getPrice(false) }}, 00 € + {{ shipping }},00 € (costo di
                spedizione)
              </td>
            </tr>
          </table>
        </div>
        <div class="linea-divisoria"></div>
        <div
          class="centered-content"
          *ngIf="
            datiAcquisto.get('tipoPagamento')?.value == 2 &&
            datiAcquisto.get('tipoCliente')?.value == 1
          "
        >
          <p>
            Verrà inviata una mail all'indirizzo da lei inserito con le
            istruzioni per il pagamento
          </p>
          <p class="send-btn">
            <button mat-raised-button color="primary" (click)="mailBonifico()">
              Invia
            </button>
          </p>
        </div>
        <div
          class="centered-content"
          *ngIf="
            datiAcquisto.get('tipoPagamento')?.value == 3 &&
            datiAcquisto.get('tipoCliente')?.value == 1
          "
        >
          <p style="margin-bottom: 20px">Inserisci dati della carta</p>
          <ngx-stripe-card
            [options]="cardOptions"
            [elementsOptions]="elementsOptions"
          ></ngx-stripe-card>
          <p class="send-btn">
            <button mat-raised-button color="primary" (click)="stripePay()">
              Paga ora
            </button>
          </p>
        </div>
        <div
          class="centered-content"
          *ngIf="
            datiAcquisto.get('tipoPagamento')?.value == 1 &&
            datiAcquisto.get('tipoCliente')?.value == 1
          "
        >
          <p>Pagamento</p>
          <br />
          <ngx-paypal [config]="payPalConfig"></ngx-paypal>
          <p style="position: relative; height: 36px">
            <button
              mat-raised-button
              matStepperPrevious
              color="primary"
              class="back-btn"
            >
              Back
            </button>
          </p>
        </div>
        <div
          class="centered-content"
          *ngIf="datiAcquisto.get('tipoCliente')?.value == 2"
        >
          <p>La contatteremo il prima possibile alla mail da lei inserita</p>
          <p class="send-btn">
            <button
              mat-raised-button
              color="primary"
              (click)="mailNoleggiatore()"
            >
              Invia
            </button>
          </p>
        </div>
      </mat-step>
    </mat-vertical-stepper>
  </div>
  <div *ngIf="outOfStock">
    <div class="sold-out"></div>
    <p class="centered-content oos-content">
      Attualmente abbiamo terminato le scorte, per sapere quando torneranno
      disponibili invia una mail a info&#64;bseasat.com.
    </p>
  </div>
</div>
<span *ngIf="completamentoOperazione"
  ><app-loading-result
    [completo]="completo"
    [errore]="errore"
    [purchase]="purchase"
  ></app-loading-result
></span>
